import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: 'FR',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        // language resources
        resources: {
            FR: {
                translation: {
                    menu: [
                        { key: 0, title: "Home", section: "home" },
                        { key: 1, title: "API Tools", section: "api_tools_id" },
                        { key: 2, title: "About", section: "about_id" },
                        { key: 3, title: "FAQ", section: "faq_id" },
                    ],

                    services: {
                        id: 'api_tools_id',
                        about: {
                            title: "API Tools",
                            description: "Free APIs tool. Performance depends heavily on usage. Please read the FAQ section for additional information including restriction. If you are looking to use this in a professional setting, please reach out.",
                        },
                        content: [
                            {
                                icon: 'fa-solid fa-receipt',
                                title: 'Receipt Reader',
                                content: "This API takes a photo of a receipt and uses advance OCR algorithms to convert characters into machine-readable formats.",
                                bg_color: "#28a745",
                                url: "receipt",
                            },
                            {
                                icon: 'fa-solid fa-magnifying-glass',
                                title: 'Image Upscaling',
                                content: "This API takes an image, converts it into greyscale, and then provide an upscaled version of the image.",
                                bg_color: "#1677ff",
                                url: "upscale",
                            },
                            {
                                icon: 'fa-solid fa-user-secret',
                                title: 'Encrypt message',
                                content: "Encrypt a message that requires a passphrase to unlock. Verify useful for carrying out transaction and other type of dealings.",
                                bg_color: "#6b53ab",
                                url: "encrypt",
                            },
                            {
                                icon: 'fa-solid fa-chart-line',
                                title: "Aeolus Forecasting",
                                content: "This API predicts values based on observed data, identified patterns, trends, and advanced modeling.",
                                bg_color: "#ec661f",
                                status: "Coming soon",
                            },
                            {
                                icon: 'fa-solid fa-eye',
                                title: "OCR Scan",
                                content: "This API takes a picture of the retinal and uses an ensemble CNN classifier to diagnose the four most common retinal diseases.",
                                bg_color: "#000080",
                                status: "Coming soon",
                            },
                            {
                                icon: 'fa-solid fa-database',
                                title: "SQL Query",
                                content: "This API uses Large-Language Model (LLM) to generate a SQL query based on the provided db schema and question.",
                                bg_color: "#BE398D",
                                status: "Coming soon",
                            },
                        ]
                    },

                    breaks: {
                        demo: {
                            id: "demo_id",
                            title: "Demo",
                            description: "Cetries Vokalia and Consonantia.",
                            url: "https://app.landing.page",
                            image: "hero",
                        },
                        competencies: {
                            id: "competencies_id",
                            title: "Technical Skills",
                            description: "APIs are built from a list of modern tools:\n• Server: Ubuntu, NGINX, Docker\n• Database: MySQL, PostgresQL, Redis\n • Frontend: ReactJS, JS, HTML, SCSS\n• Backend: Django, Python\n • Others: UI/UX design, Git",
                            url: "",
                            image: "img4",
                        },
                        about: {
                            id: "about_id",
                            title: "About",
                            description: "SQL Bond is built to showcase some of my work, offering visitors a glimpse of my technical expertise. Beyond being a personal portfolio, I hope to inspire and collaborate with like-minded individuals, fostering an environment where ideas can flourish, and innovations can thrive.",
                            url: "",
                            image: "img1",
                        },
                    },
                    faq: {
                        id: 'faq_id',
                        about: {
                            title: 'Frequent Asked Questions',
                            description: "Please read this before using any service provided by this website.",
                            price: "Free",
                            bg_color: "#5cb100",
                            url: "https://app.landing.page",
                        }
                        ,
                        content: [
                            {
                                qn: "What is the purpose of SQLBond.com and for providing Free APIs?",
                                ans: "SQL Bond serves as a showcase of some of my technical capabilities. It is also a good thing to share the fruits of my labor with the community in hope to inspire and collaborate with like-minded individuals"
                            },
                            {
                                qn: "Why SQL Bond?",
                                ans: "SQL is one my favourite languages, and intriguingly, 'Bond' is a French term denoting 'Leap.' The fusion of these words gives rise to SQL Bond, representing the leap forward in the evolution of SQL."
                            },
                            {
                                qn: "Do I need an account?",
                                ans: "No. We value simplicity although it is a feature that we may implement in the future."
                            },
                            {
                                qn: "Are the APIs free to use?",
                                ans: "Yes, the APIs are free to use and without having to attribute the author (although giving credit is always appreciated!)."
                            },
                            {
                                qn: "How long are the data stored in the database?",
                                ans: "We have limited space on our server. We may cleanout, delete, and reset the database when needed without prior warning. We are not responsible for any damage in result to loss of data, information, etc..."
                            },
                            {
                                qn: "What kind of reliability or guarantee on the AIPs?",
                                ans: "None. We do not guarantee that the APIs (Services) will always be available, free, uninterrupted, timely, secure, and free from bugs or error."
                            },
                            {
                                qn: "Is SQL Bond and the author liable for any damage that users may incurred?",
                                ans: "No. We are not liable for any loss or damage you may suffer as a result of visiting this website and utilizing any of our Service. This includes physical, moral, and all other possible damages. This is a free service offered under no warranty or guaranty."
                            },
                            {
                                qn: "Are there any terms and conditions?",
                                ans: "Yes, you agree to use these APIs in good faith and legally, and you agree to all the conditions listed in this FAQ section."
                            },
                            {
                                qn: "What information do you keep?",
                                ans: "In order to fight abuse and malicious usage, the following information are kept: datetime, IP, and details about the request."
                            },
                            {
                                qn: "I would like to contribute to the community through donation. Is this possible?",
                                ans: "Thank you for wanting to supporting my work. The best way to support me is to share it with your communities."
                            },
                        ]
                    },

                }
            },
        }
    });

export default i18n;